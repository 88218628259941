@use '../../../../../../../shared/scss-helpers/fonts.scss';

@import '../../../../../../../shared/scss-helpers/mixins.scss';
@import '../../../../../../../shared/scss-helpers/colors.scss';

.ExpansionButton {
  &Container {
    @include flexbox(row, center, flex-end);
    width: 100%;
    margin-top: var(--vertical-spacing-3);
  }

  font: 700 12px fonts.$franklin;
  color: $newsGray100;
  border: none;
  background-color: transparent;
  padding: 0;
  margin-right: calc(var(--horizontal-spacing-0-5) * 4.5);
}

:global(.dark) {
  .ExpansionButton {
    color: white;
  }
}

@include tiny-phone {
  .ExpansionButtonContainer {
    margin-top: var(--vertical-spacing-2);
  }
}
