.gameContainer {
  position: relative;
  height: 100%;
}

.game {
  width: 100%;
  max-width: var(--game-max-width);
  margin: 0 auto;
  /*
  We use a different element for the header in app which is
   */
  height: calc(100% - var(--header-height));

  display: flex;
  flex-direction: column;

  &.withToolbar {
    height: calc(100% - var(--header-toolbar-height));
  }
}

:global(.pz-offline-ticker) {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 2;
  font-weight: bold;

  svg {
    path {
      fill: var(--white);
    }
  }
}

:global(.portal-content) {
  position: relative;
  top: calc(var(--header-height) + 5px);
}
