@import '../../../shared/scss-helpers/index.scss';
@import '../../../shared/scss-helpers/mixins.scss';
@import '../../../shared/scss-helpers/variables.scss';

:root {
  --wordle-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/Wordle-Icon-np.svg');
  --connections-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/Connections-Icon-np.svg');
  --daily-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/Crossword-Icon-np.svg');
  --spelling-bee-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/Spelling-Bee-Icon-np.svg');
  --mini-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/Mini-Icon-np.svg');
  --strands-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/Strands-Icon-np.svg');
  --mini-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/Mini-Icon-np.svg');
  --sudoku-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/Sudoku-Icon-np.svg');
  --tiles-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/Tiles-Icon-np.svg');
  --letter-boxed-np: url('https://www.nytimes.com/games-assets/v2/assets/wordle/page-icons/LetterBoxed-Icon-np.svg');
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-10px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  68% {
    transform: scale(1, 1) translateY(-1px);
  }
  75% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

.hamburgerCTAContainer {
  width: 332px;
  height: 228px;
  background-color: $newsDarkContentPrimary;
  border-radius: 16px;
  margin: var(--vertical-spacing-2) auto 0;
  display: flex;
  flex-direction: column;
}

.iconContainer {
  width: 300px;
  height: 30px;
  display: flex;
  justify-content: space-evenly;
  margin: var(--vertical-spacing-3) auto 0;

  > * {
    width: 25px;
    height: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    margin: calc(var(--spacing-0-5) / 2);
  }
}

.wordleIcon {
  background-image: var(--wordle-np);
}
.connectionsIcon {
  background-image: var(--connections-np);
}
.xwdIcon {
  background-image: var(--daily-np);
}
.sbIcon {
  background-image: var(--spelling-bee-np);
}
.miniIcon {
  background-image: var(--mini-np);
}
.strandsIcon {
  background-image: var(--strands-np);
}
.sudokuIcon {
  background-image: var(--sudoku-np);
}
.tilesIcon {
  background-image: var(--tiles-np);
}
.lbIcon {
  background-image: var(--letter-boxed-np);
}

.animateSharedNav,
.animateWordleNav {
  div {
    display: inline-block;
  }

  div:first-child {
    animation: 1s linear 5ms 1 forwards bounce;
  }

  div:nth-child(2) {
    animation: 1s linear 70ms 1 forwards bounce;
  }

  div:nth-child(3) {
    animation: 1s linear 135ms 1 forwards bounce;
  }

  div:nth-child(4) {
    animation: 1s linear 200ms 1 forwards bounce;
  }

  div:nth-child(5) {
    animation: 1s linear 265ms 1 forwards bounce;
  }

  div:nth-child(6) {
    animation: 1s linear 330ms 1 forwards bounce;
  }

  div:nth-child(7) {
    animation: 1s linear 395ms 1 forwards bounce;
  }

  div:nth-child(8) {
    animation: 1s linear 460ms 1 forwards bounce;
  }

  div:nth-child(9) {
    animation: 1s linear 520ms 1 forwards bounce;
  }
}

.textContainer {
  @include flexbox(column, center);
  padding-top: var(--vertical-spacing-2);

  h1 {
    text-align: center;
    font: 28px/1 $karnak-condensed;
    color: $black;
  }

  p {
    margin-top: var(--vertical-spacing-1);
    text-align: center;
    font: 16px/115% $franklin;
    color: $gray27;
  }
}

.button {
  height: 32px;
  width: 178px;
  border-radius: 1.5em;
  background: $newsGray100;
  margin: var(--vertical-spacing-1) auto;
  text-align: center;
  align-content: center;
  font: 600 14px $franklin;
  color: $newsDarkContentPrimary;
}

@include tiny-phone {
  .hamburgerCTAContainer {
    height: 180px;
  }

  .iconContainer {
    margin: var(--vertical-spacing-2) auto var(--vertical-spacing-1);

    > * {
      width: 20px;
      height: 20px;
    }
  }

  .textContainer {
    padding-top: 0;

    h1 {
      font-size: 24px;
    }

    p {
      font-size: 14px;
    }
  }

  .button {
    height: 20px;
    background: none;
    color: $black;
    text-decoration: underline;
  }
}
