@use '../../../../../../../shared/scss-helpers/fonts.scss';

@import '../../../../../../../shared/scss-helpers/mixins.scss';
@import '../../../../../../../shared/scss-helpers/colors.scss';

.directLink {
  @include flexbox(row, center, flex-start);
  font: 15px fonts.$franklin;
  margin-top: calc(
    var(--vertical-spacing-0-5) * -0.25
  ); // make bottom border line up with hover style
  width: 100%;

  &Container {
    width: 100%;
  }

  &__icon {
    padding-bottom: calc(var(--vertical-spacing-0-5) * 0.5);
    content: '';
    height: 20px;
    width: 28px;
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: '20px';
  }

  &__description {
    max-width: 280px;
    align-self: center;
  }

  &__pill {
    background-color: var(--color-tone-12);
    color: var(--color-tone-7);
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 0.08rem;
    line-height: 1.2;
    padding: 0.2rem 0.4rem;
    margin: auto 0 auto 0.75rem;
    border-radius: 6px;
  }

  &:hover {
    background-color: $gray12;
  }
}

.directLink--boldText {
  font-weight: bold;
}

.directLink--additionalIndent {
  padding: calc(var(--vertical-spacing-0-5) * 2.5)
    calc(var(--horizontal-spacing-0-5) * 4.5)
    calc(var(--vertical-spacing-0-5) * 2.5)
    calc(var(--horizontal-spacing-0-5) * 5.75) !important;
}

:global(.dark) {
  .directLink:hover {
    background-color: $gray27;
  }
}
