.adContainer {
  border-bottom: 1px solid #e6e6e6;
  box-shadow: inset 0 0 60px -10px rgba(0, 0, 0, 0.07);
  text-align: center;
  min-height: 300px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  &:empty {
    border: none;
  }
}
.hasAdLabel {
  &:before {
    content: 'advertisement';
    color: #bbb;
    font-size: 12px;
    border: 1px solid #ccc;
    padding: var(--vertical-spacing-1) var(--horizontal-spacing-1-5);
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}
