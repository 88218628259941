.regiModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: var(--vertical-spacing-2);
}

.copyContainer {
  max-width: 260px;
  margin-top: calc(var(--vertical-spacing-0-5) * 8.75);

  .copyHeader {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: calc(var(--vertical-spacing-0-5) * 3.5);
  }

  .copyBody {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }
}

.loginLink {
  background-color: var(--color-tone-1);
  color: var(--color-tone-7);
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  box-sizing: border-box;
  border: 3px solid var(--color-tone-1);
  border-radius: 55px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-decoration: none;
  padding: calc(var(--vertical-spacing-0-5) * 3.5)
    calc(var(--horizontal-spacing-0-5) * 1.25);
  margin: var(--vertical-spacing-5) 0 var(--vertical-spacing-3);
  max-width: 340px;
  width: 100%;
  > a {
    color: inherit;
    text-decoration: none;
  }
}

.statsIcon {
  height: 148px;
  width: 148px;
}
