.backButton {
  background: transparent;
  border: 0;
  padding: 0;

  &:before {
    content: '';
    background: var(--hybrid-back) center no-repeat;
    background-position-x: 0px;
    display: block;
    height: 45px;
    padding: 0px calc(var(--horizontal-spacing-0-5) * 3.75);
    opacity: 1;
  }

  &:active:before {
    opacity: 0.5;
  }
}

:global(.dark) {
  .backButton {
    &:before {
      background: var(--hybrid-back-dark-mode) center no-repeat;
      background-position-x: 0px;
    }
  }
}

.backButtonText {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}
