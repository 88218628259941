.newNav {
  display: flex;
  flex-direction: column;

  a {
    color: inherit;
    text-decoration: none;
  }
}

:global(.dark) {
  .newNav {
    background-color: #1b1b1b;
  }
}
