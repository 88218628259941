@import '../../../../../shared/scss-helpers/mixins.scss';

.overlayNav {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: unset;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: var(--modal-z-index);
  background-color: transparent;

  &:focus {
    outline: none;
  }
}

.contentNav {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid var(--color-tone-6);
  background-color: var(--modal-content-bg);
  color: var(--color-tone-1);
  overflow-y: auto;
  overflow-x: hidden;
  animation: SlideRight 200ms;
  max-width: var(--game-max-width);
  box-sizing: border-box;
  width: 100%;
  border-radius: 0px;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.15);
  height: 100vh;
  padding: 0px;
}

.contentNav.closingNav {
  animation: SlideLeft 200ms;
}

.headerContainer {
  box-sizing: border-box;
  position: relative;
  padding: var(--vertical-spacing-0-5) 0;
  border-bottom: 1px solid #dfdfdf;
  height: var(--header-height);
  &.toolbarVariant {
    min-height: 48px;
  }
}

.closeIconNav {
  background: none;
  border: none;
  padding: calc(var(--vertical-spacing-0-5) * 1.5)
    var(--horizontal-spacing-1-5);
  width: 24px;
  height: 24px;
  user-select: none;
  cursor: pointer;
  &.toolbarVariant {
    padding: calc(var(--vertical-spacing-0-5) * 2.25)
      var(--horizontal-spacing-1-5);
  }
}

.nytIconContainer {
  position: absolute;
  text-align: center;
  margin: auto;
  top: 10px;
  left: 0;
  right: 0;
  &.toolbarVariant {
    top: 15px;
  }
}

@keyframes SlideRight {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

@keyframes SlideLeft {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateX(-200px);
  }
}

@include tablet-and-desktop {
  .contentNav {
    width: 375px;
  }
}

@media (min-width: 415px) {
  .headerContainer {
    padding: 0;
    display: flex;
    align-items: center;
  }

  .closeIconNav {
    padding: calc(var(--vertical-spacing-0-5) * 0.25)
      var(--horizontal-spacing-1-5);
    margin-right: var(--horizontal-spacing-6);
    transform: scale(1.3);
    &.toolbarVariant {
      padding: 0 var(--horizontal-spacing-1-5);
    }
  }

  .nytIconContainer {
    position: relative;
    margin: 0;
    top: auto;
    left: auto;
    right: auto;
    transform: scale(1.3);
    &.toolbarVariant {
      top: 0;
    }
  }
}

@media (min-width: 1024px) {
  .headerContainer {
    &.toolbarVariant {
      min-height: 56px;
    }
  }
}
