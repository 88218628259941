@use '../../../../../../../shared/scss-helpers/fonts.scss';
@import '../../../../../../../shared/scss-helpers/mixins.scss';
@import '../../../../../../../shared/scss-helpers/colors.scss';

.collapsibleLink {
  @include flexbox(row, center, space-between);
  margin-top: calc(
    var(--vertical-spacing-0-5) * -0.25
  ); // make bottom border line up with hover style

  &__button {
    height: 28px;
    width: 28px;
    background: none;
    border: none;
    margin: calc(var(--vertical-spacing-0-5) * 2.5)
      calc(var(--horizontal-spacing-3)) 0 0;
  }

  &:hover {
    background-color: $gray12;
  }
}

@function left-border($color) {
  @return 5px solid $color;
}

.isexpanded {
  background-color: $gray12;

  &_crossword {
    border-left: left-border($daily-crossword-blue);
  }

  &_mini {
    border-left: left-border($mini-crossword-blue);
  }

  &_connections {
    border-left: left-border($connections-periwinkle);
  }

  &_spelling-bee {
    border-left: left-border($spelling-bee-gold);
  }

  &_wordle {
    border-left: left-border($gray13);
  }

  &_letterboxed {
    border-left: left-border($letter-boxed-red);
  }

  &_tiles {
    border-left: left-border($tiles-green);
  }

  &_strands {
    border-left: left-border(#b2ded8);
  }

  &_sudoku {
    border-left: left-border($sudoku-orange);
  }
}

:global(.dark) {
  .isexpanded,
  .collapsibleLink:hover {
    background-color: $newsGray85;
  }
}
