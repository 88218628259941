@use '../../../../../../../shared/scss-helpers/fonts.scss';

@import '../../../../../../../shared/scss-helpers/colors.scss';

.linkGroup {
  list-style: none;
  color: var(--color-tone-1);
  padding: unset;
  margin: unset;

  &__header {
    font: 700 12px fonts.$franklin;
    text-transform: uppercase;
    line-height: 12px;
    margin: var(--vertical-spacing-1-5) 0;
    padding-left: calc(var(--horizontal-spacing-0-5) * 4.5);
  }

  ul.isGameLink .link {
    border-left: 5px solid transparent;

    // add gray borders for only game links that don't take up entire width
    &:not(:first-child)::before {
      content: '';
      display: block; // do not add display: flex here bc Safari won't respect it on this pseudo-element
      margin: 0 auto;
      width: 90%;
      /* or 100px */
      border-top: 1px solid $newsGray10;
    }
  }

  // list item styling (applies to Collapsible Link and Direct Link)
  .link {
    margin-bottom: calc(
      var(--vertical-spacing-0-5) * -0.25
    ); // make border line up with hover style

    a {
      padding: calc(var(--vertical-spacing-0-5) * 2.5)
        calc(var(--horizontal-spacing-0-5) * 4.5);
    }

    // remove bold for nested links
    & ul a {
      font-weight: 500;
    }

    &_tips-and-tricks,
    &_privacy-settings {
      // remove the border left since this is a collapsible link header
      border: none;
    }

    &_pips:not(:has(ul)):hover {
      border-color: $pips-pink;
    }
    &_crossword:not(:has(ul)):hover {
      border-color: $daily-crossword-blue;
    }
    &_mini:not(:has(ul)):hover {
      border-color: $mini-crossword-blue;
    }
    &_connections:not(:has(ul)):hover {
      border-color: $connections-periwinkle;
    }
    &_spelling-bee:not(:has(ul)):hover {
      border-color: $spelling-bee-gold;
    }
    &_wordle:not(:has(ul)):hover {
      border-color: $gray13;
    }
    &_letterboxed:not(:has(ul)):hover {
      border-color: $letter-boxed-red;
    }
    &_tiles:not(:has(ul)):hover {
      border-color: $tiles-green;
    }
    &_strands:not(:has(ul)):hover {
      border-color: #b2ded8;
    }
    &_sudoku:not(:has(ul)):hover {
      border-color: $sudoku-orange;
    }
  }

  // remove space before "view all games"
  ul.isGameLink li {
    border-left: none;
    &:last-child a span {
      display: none;
    }
  }

  li:last-child {
    margin-bottom: 0.5rem;
  }
}

:global(.dark) {
  li.link:not(:has(ul)):hover {
    background-color: $newsGray85;
  }

  ul.isGameLink li.link:not(:first-child)::before {
    border-top: 1px solid $newsGray85;
  }
}

// prevent double border
.isexpanded {
  border-left-width: 0 !important;
}
