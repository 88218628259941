@import '../../../../../shared/scss-helpers/colors.scss';
@import 'shared/scss-helpers/mixins.scss';

.navLoggedIn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  padding: 8px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
}

.navLoggedOut {
  @extend .navLoggedIn;
  padding: 16px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
}

.navDrawerHeading {
  font-family: 'nyt-franklin';
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.75px;
  line-height: 14px;
  display: block;
  padding: 0px calc(var(--horizontal-spacing-0-5) * 2.5);
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.navDrawerAccount {
  border-top: 1px solid $newsGray100;
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: var(--color-tone-7);
  margin-top: auto;
}

.navProfileAccount {
  padding: 5px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
}

.navButton {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  font-family: 'nyt-franklin';
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.047em;
  text-transform: uppercase;
  height: 36px;
  border: 1px solid #f4f4f4;
  border-radius: 3px;
  color: #fff;
  background-color: #000;
  padding: calc(var(--vertical-spacing-0-5) * 0.25)
    calc(var(--horizontal-spacing-0-5) * 8.25) 0;
  cursor: pointer;
  text-decoration: none;

  a + & {
    margin-left: var(--horizontal-spacing-1);
  }
}

.navDrawerLink {
  font-family: 'nyt-franklin';
  display: block;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  letter-spacing: 0.5px;
  border-left: 4px solid transparent;
  padding: 0 var(--horizontal-spacing-2) 0 var(--horizontal-spacing-1);
  text-decoration: none;
  color: inherit;
}

.navDrawerLink:hover {
  background-color: var(--color-nav-hover);
}

.loginButton {
  @extend .navButton;
  color: #000;
  border-color: #000;
  background-color: #fff;
}

.loginButton:hover {
  color: #fff;
  background-color: #000;
}

.subscribeButton {
  @extend .navButton;
  color: #fff;
  background-color: #000;
}

.subscribeGamesSale {
  padding: calc(var(--vertical-spacing-0-5) * 0.25)
    calc(var(--horizontal-spacing-0-5) * 2.5) 0;
  white-space: nowrap;
}

.subscribeButton:hover {
  background-color: #797987;
  border: none;
}

.logoutButton {
  @extend .navButton;
  color: var(--color-tone-1);
  background-color: var(--color-tone-7);
  border: 1px solid var(--color-tone-1);
  border-radius: 3px;
}

.logoutButton:hover {
  background-color: #ebebeb;
  color: var(--black);
}

.navGamesSale {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  padding: 0 var(--horizontal-spacing-1) var(--vertical-spacing-1);

  .logoutButton {
    margin-left: var(--horizontal-spacing-1);
  }
}

.subscribeGamesSale {
  padding: calc(var(--vertical-spacing-0-5) * 0.25)
    calc(var(--horizontal-spacing-0-5) * 2.5) 0;
  letter-spacing: 0.2px;
  margin: 0 var(--horizontal-spacing-0-5);
}

.subscribeGamesSale:hover {
  border: 1px solid var(--color-tone-1);
}
.noWrap {
  white-space: nowrap;
}

:global(.dark) {
  .navDrawerAccount {
    border-top: 1px solid $newsDarkContentPrimary;
  }
}

@include tiny-phone {
  .navDrawerAccount {
    .navProfileAccount {
      .navTextContainer {
        width: 345px;
        display: flex;
        margin-left: var(--vertical-spacing-1);

        .navDrawerHeading {
          width: 50%;
          padding-left: 0;
        }

        .navDrawerLink {
          display: flex;
          justify-content: flex-end;
          padding-right: 0;
          width: 50%;
          text-decoration: underline;
        }
      }
    }
  }
}
