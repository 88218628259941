.setting {
  font-family: 'nyt-franklin';
  font-weight: '500';
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-tone-4);
  padding: var(--vertical-spacing-2) 0;

  a,
  a:visited {
    color: var(--color-tone-8);
    text-decoration: underline;
  }
}

.title {
  font-size: 18px;
}

.text {
  padding-right: var(--horizontal-spacing-1);
}

a.feedbackLink {
  text-decoration: underline;
}

.description {
  font-size: 12px;
  color: var(--color-tone-12);
}

.footnote {
  padding-top: var(--vertical-spacing-2);
  color: var(--color-tone-12);
  font-size: 12px;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.container {
  flex: 1;
}

@media (min-width: 501px) {
  .footnote {
    padding-bottom: var(--vertical-spacing-2);
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .setting {
    padding: 16px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
  }
}
