@import '../../../sharedStyles.scss';

.toast {
  font-family: 'nyt-franklin';
  position: relative;
  margin: 16px; // TODO NONDIRECTIONAL SPACING TOKEN REQUIRED
  background-color: var(--color-tone-8);
  color: var(--color-tone-7);
  padding: 13px; // TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
  border: none;
  border-radius: 4px;
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  &_regiwall {
    margin: 0;
    width: 150%;
    transform: translate(-20%, 0);
    text-align: center;
  }
}

.win {
  background-color: var(--color-correct);
  color: var(--tile-text-color);
}

.fade {
  opacity: 0;
}
