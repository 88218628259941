.tile {
  font-family: 'nyt-franklin';
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  line-height: 1;
  font-weight: bold;
  vertical-align: middle;
  box-sizing: border-box;
  color: var(--tile-text-color);
  text-transform: uppercase;
  user-select: none;
}
.tile::before {
  content: '';
  display: inline-block;
  padding-bottom: 100%;
}

.tile.small {
  font-size: 1.6rem;
  padding-top: calc(
    var(--vertical-spacing-0-5) * 0.25
  ); // for vertical alignment of letter
}

/* Allow tiles to be smaller on small screens */
@media (max-height: 600px) {
  .tile {
    font-size: 1em;
  }
}

.tile[data-state='empty'] {
  border: 2px solid var(--color-tone-4);
}

.tile[data-state='tbd'] {
  background-color: var(--color-tone-7);
  border: 2px solid var(--color-tone-3);
  color: var(--color-tone-1);
}

.tile[data-state='correct'] {
  background-color: var(--color-correct);
  color: var(--key-evaluated-text-color);
}

.tile[data-state='present'] {
  background-color: var(--color-present);
  color: var(--key-evaluated-text-color);
}

.tile[data-state='absent'] {
  background-color: var(--color-absent);
  color: var(--key-evaluated-text-color-absent);
}

.tile[data-animation='pop'] {
  animation-name: PopIn;
  animation-duration: 100ms;
}

@keyframes PopIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }

  40% {
    transform: scale(1.1);
    opacity: 1;
  }
}

.tile[data-animation='flip-in'] {
  animation-name: FlipIn;
  animation-duration: 250ms;
  animation-timing-function: ease-in;
}

@keyframes FlipIn {
  0% {
    transform: rotateX(0);
  }
  100% {
    transform: rotateX(-90deg);
  }
}
.tile[data-animation='flip-out'] {
  animation-name: FlipOut;
  animation-duration: 250ms;
  animation-timing-function: ease-in;
}

@keyframes FlipOut {
  0% {
    transform: rotateX(-90deg);
  }
  100% {
    transform: rotateX(0);
  }
}
