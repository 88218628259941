.container {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: calc(100% - var(--header-height) - 1px);
  left: 0;
  justify-content: center;
  background-color: var(--color-background);
  z-index: var(--error-z-index);
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 82px;
  height: 100%;
  transition: opacity 0.2s;
  opacity: 1;
}

.hide {
  opacity: 0;
}
