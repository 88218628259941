@import '../../../shared/scss-helpers/mixins.scss';

.container {
  display: none;
}

@media (min-height: 650px) {
  .container:not(.mobile) {
    display: block;
    padding: 0 0 20px;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
}

.sectionLink {
  width: auto;
}

.legalLinksContainer {
  display: block;
  margin: auto;
  max-width: fit-content;
  text-align: center;
}

.legalLink {
  display: inline-flex;
  vertical-align: middle;

  a {
    text-decoration: none;
    color: inherit;
  }

  a,
  span {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.7px;
  }
  padding: 0;
  margin: 0;
  color: var(--key-text-color, #000);

  & + ::before {
    color: var(--key-text-color, #000);
    padding: 0 5px;
  }

  .privacyChoices {
    line-height: 13px;
    display: flex;
  }
}

@include phone {
  .legalLinksContainer {
    @include flexbox(column, flex-start);
    line-height: 13px;
  }

  .legalLink {
    margin-bottom: 10px;
  }

  :global(.pz-footer__legal-co) {
    text-align: left;
  }
}

@include tablet-and-desktop {
  .legalLink + ::before {
    content: '|';
  }
}
