.landscapeWarning {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: var(
    --horizontal-warning-z-index
  ); // Landscape warning needs to sit on top of modals
  background-color: var(--white);
  text-align: center;
  padding-top: 55px; // $mobile-header-height + $breathing-room; TODO: NONDIRECTIONAL SPACING TOKEN REQUIRED
  i {
    height: 30%;
    width: 20%;
    margin: 2rem;
  }

  p {
    font-family: 'nyt-franklin';
    font-weight: 500;
    margin: 0;
    font-size: 16px;
    font-size: calc(16 * 0.0625rem);
    line-height: 20.8px;
    line-height: calc(20.8rem * 0.0625);
  }

  span {
    font-family: 'nyt-franklin';
    font-weight: 700;
  }
}

.darkMode {
  background-color: black;
  color: white;
}

.rotate {
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  min-height: 20px;
  min-width: 20px;
  margin-right: var(--horizontal-spacing-1);
  background-image: var(--icon-rotate-wordle);
}
